import React from 'react'

const AboutPage = () => {
  return (
    <div className="main">
      <h3>What is QOTW?</h3>
      <p>Quote of the Week is a self-described joke that got out of hand which started at Commonwealth School in 2002. Each week, students and faculty <a href="/Submit">submit</a> funny or deep or “funny” or “deep” quotes, and they <a href="/Vote">vote</a> on last week's submissions to select their favorite quotes. Quotemasters send out, shall we say, flavored weekly newsletters announcing the winner. Before this current website came into existence, voting and presenting candidate quotes all happened through email, but fear no more: now, everything can be done on this website. </p>
      <h3>How It All Began</h3>
      <div>
      <h5>The Short Version</h5>
      <p>An inside joke got totally out of control. I blame Alex George for his relentless support and Julian Hyde for his relentless humor.</p>
      <h5>The Long Version</h5>
      <p>It was an historic train ride. Apocryphal, perhaps, but even its fiction sends shockwaves into the next millennium of American Democracy, redefining our views of equality and morality the way no other spoken words ever could. Hastily scribbled on the back of a napkin, the irony is that those destiny-laden words are now permanently etched in our collective thoughts and values.</p>
      <p>But the fact is that Abraham Lincoln’s Gettysburg Address has nothing to do with Quote of the Week.</p>
      <p>“Four score and seven years ago...” Sure, it’s eloquent and all that; and the idea of it being written on a train, of all places, appeals to our sense of historical irony and destiny; but the fact remains that Lincoln was a tall hairy man and he was never in Quote of the Week. (Compare that to Jeff Kaufman, a tall hairy man who has been in Quote of the Week. I think we all know which one history will smile upon.)</p>
      <p>The American Presidency has given us such “heroes” as Millard Fillmore and Bill Clinton; Quote of the Week has given us true visionaries such as Anthony Campbell and Julian “gender is an illusion” Hyde.</p>
      <p>I’m not saying that Quote of the Week should replace the Electoral College as a method for electing our nation’s leaders; I’m just throwing the idea out there. The newspaper sure would be a lot wittier to read.</p>
      <p>But that’s not what I’m trying to tell you about: I’m trying to tell you about how Quote of the Week rose from complete obscurity to its current glorious state of near-complete obscurity.</p>
      <p>It all began one fateful week in April, 2002. Having overheard a handful of humorous quotes over the week past, and feeling my buddy profile on AOL Instant Messenger to be woefully lacking, I put four quotes up in my profile as nominees for “Quote of the Week.” It was a small joke. I declared a winner after talking about it to a few friends, and wasn’t planning to do it again the next week, but then something happened. Something extraordinary.</p>
      <p>Well, not that extraordinary. Unless you consider Alex George’s hair back then to be extraordinary, which it sort of was; I mean, it was longer than most professional baseball games, and more tangled than the electron configuration for Niobium.</p>
      <p>Anyway, Alex came up to me with a quote that he thought should definitely, absolutely be a contender for Quote of the Week. I decided to humor him and keep it alive for another week, scrounging up a few more nominees to round out the assortment. I consulted with a few key people on Instant Messenger, and they gave me their votes as to which one should win. But I went ahead and chose the one I liked best, ignoring the votes entirely. It was a tyrannical operation in those days.</p>
      <p>And so it continued, week to week, until school let out. I ran all the winners together as nominees in one week and called the champion the Quote of the Year.</p>
      <p>But Quote of the Week didn’t die out over the summer the way it should have. Too many people knew about its humble little existence; too many people kept saying to me, “There’s a quote of the week!” and “Write it down, Ben, write it down!” It only got worse as sophomore year arrived. Eventually, at the suggestion of several people, I decided to take Quote of the Week out of my AIM profile and into the wide world of email. And, in the most radical change of all, the people themselves chose the winner, in a pure democracy. I had voluntarily relinquished my dictatorship.</p>
      <p>That marked the beginning of the golden age for Quote of the Week, which has been a lot like the golden age of Islam, only with more pork. QOTW—an abbreviation first used by Alex—quickly skyrocketed in popularity. New members were joining every week. QOTW made the switch from normal plurality voting to the vastly superior Instant Runoff Voting system. Teachers, fifth graders, and family members alike participated in the process. With so many quotes pouring in every week from so many sources, I was forced to delegate the excess to a secondary “Honorable Mentions” email that supplemented the main one. Members told their friends about QOTW, and all of a sudden people I had never heard of were joining.</p>
      <p>But QOTW isn’t done yet. How long do you think it’ll be before Random House publishes a QOTW compilation book, and how long before that book hits #1 on the New York Times Bestseller list? How long will it be before the TV networks catch on (granted, they can be a little dim sometimes) and offer me a half-hour weekly primetime slot? How long before Disney pays me nine figures for the rights to a QOTW film? How long before said film is followed with a Broadway musical?</p>
      <p>Not long, my friend. Not long.</p>
      <p>But for the time being, this web site marks the next stage in QOTW’s master plan to bring peace, prosperity, and hilarious quotes to human civilization. So enjoy it; cherish it; send me money so I can afford to pay for it. You (yes, even you, John) can help by saying funny things and voting every week; not just for my sake, but for the world’s. As a very wise man (Julian Hyde) would say: “Think of the children!”</p>
      <p><small>—Ben Orlin, founder of QOTW</small></p>
      </div>
    </div>
  )
}

export default AboutPage